import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AlertTriangle, Check, Edit, Eye, Plus, Trash2, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import usePathName from "../../hooks/usePathName";
import { api } from "../../config/Api";
import useTable from "../../hooks/useTable";
import CustomTable from "../table/CustomTable";
import DeleteModal from "../../modal/DeleteModal";
import { onActivateDeactivateFamily, onDeleteFamily } from "../../service/families";
import { ActivateDeactivateFamily, Alignment, FamiliesMetaFilter, Family, TableStateValue } from "../../helper/interface";
import avatar from "./../../assets/images/userAvatar.png";
import { Formik } from "formik";
import Filter from "./Filter";

let initialValues: FamiliesMetaFilter = {
    searchBy: "Village",
};

const Families = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tableFilterData = useSelector((state: TableStateValue) => state.table.tableData);

    useEffect(() => {
        if (tableFilterData.metaFilter) {
            const metaFilter = tableFilterData.metaFilter;

            initialValues = {
                searchBy: metaFilter.searchBy,
                value: metaFilter.value,
            };
        } else {
            initialValues = {
                searchBy: "Village",
                value: null,
            };
        }
    }, [tableFilterData.metaFilter]);

    const familiesTable = useTable<Family>("families", api.family, false);

    const [isOpen, setIsOpen] = useState(false);
    const [family, setFamily] = useState<Family | null>(null);

    const onDeleteClick = useCallback((record: Family) => {
        setFamily(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (family?.familyId) {
            onDeleteFamily(family?.familyId, dispatch, () => {
                familiesTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [family, familiesTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateFamily) => {
            onActivateDeactivateFamily(payload, dispatch, familiesTable.fetchApi);
        },
        [familiesTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const familiesCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Family, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "PROFILE PIC.",
            key: "profileImg",
            render: (record: Family) => {
                return (
                    <span>
                        <img
                            src={record?.profileImg ?? avatar}
                            alt="Profile picture"
                            className="rounded-full object-fit-cover-center thumb-hw-50 mx-auto"
                        />
                    </span>
                );
            },
        },
        {
            title: "VILLAGE",
            key: "villageName",
            sortable: true,
            render: (record: Family) => {
                return <span className="capitalize">{record?.village?.nameEn ? record?.village?.nameEn : "--"}</span>;
            },
        },
        {
            title: "PRIMARY MEMBER",
            key: "primaryMemberName",
            minWidth: true,
            sortable: true,
            render: (record: Family) => {
                return <span className="capitalize">{record?.primaryMember?.fullNameEn ? record?.primaryMember?.fullNameEn : "--"}</span>;
            },
        },
        {
            title: "COUNT",
            key: "memberCount",
            sortable: false,
            render: (record: Family) => {
                return <span>{record?.members?.length}</span>;
            },
        },
        {
            title: "PHONE",
            key: "mobile",
            sortable: false,
            render: (record: Family) => {
                return <span>{record?.mobile ? record?.mobile : "-"}</span>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Family) => {
                const payload = {
                    familyId: record?.familyId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "VERIFIED",
            key: "isVerified",
            render: (record: Family) => {
                return (
                    <div className="text-center">
                        {record?.isVerified ? <Check className="text-success mx-auto" /> : <X className="text-danger mx-auto" />}
                    </div>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Family) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer text-primary"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Add family member"}
                                onClick={() =>
                                    navigate(`/families/${record?.familyId}/members/add`, {
                                        state: {
                                            familyId: record?.familyId,
                                        },
                                    })
                                }
                            >
                                <Plus className="w-4 h-4 mr-1" />
                                Add
                            </div>
                            <div
                                className="flex items-center mr-3 cursor-pointer text-success"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"View family details"}
                                onClick={() => navigate(`/families/view/${record?.familyId}`)}
                            >
                                <Eye className="w-4 h-4 mr-1" />
                                View
                            </div>
                            <div
                                className="flex items-center mr-3 cursor-pointer text-primary"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit family"}
                                onClick={() => navigate(`/families/edit/${record?.familyId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className={`flex items-center cursor-pointer ${record?.deletedAt ? "color-red" : "text-danger"}`}
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete family"}
                                onClick={() => onDeleteClick(record)}
                            >
                                {record?.deletedAt ? <AlertTriangle className="w-4 h-4 mr-1" /> : <Trash2 className="w-4 h-4 mr-1" />}
                                <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const onMetaFilteredSubmit = (value: FamiliesMetaFilter) => {
        const metaFilter = {
            searchBy: value.searchBy,
            value: value.value,
        };

        familiesTable.onSearch("", metaFilter);
    };

    usePathName([{ pathName: "Families", href: "/families" }]);

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this family?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={familiesCols}
                    data={familiesTable.tableData ?? []}
                    tableKey="familyId"
                    reload={familiesTable.fetchApi}
                    currentOrder={familiesTable.currentOrder}
                    error={familiesTable.error}
                    loading={familiesTable.loading}
                    tableOffset={familiesTable.tableOffset}
                    onSetOrderBy={familiesTable.onSetOrderBy}
                    className="xl:justify-between xl:items-center"
                    component={
                        <>
                            <Formik initialValues={initialValues} enableReinitialize onSubmit={onMetaFilteredSubmit}>
                                {(props) => <Filter {...props} reload={familiesTable.fetchApi} />}
                            </Formik>
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto whitespace-nowrap"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new family"
                                    onClick={() => {
                                        navigate("/families/add");
                                    }}
                                >
                                    Add New Family
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Families;
