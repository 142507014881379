import { useCallback } from "react";
import { Form } from "formik";
import { Listbox, Transition } from "@headlessui/react";
import Select, { MultiValue } from "react-select";
import { useDispatch, useSelector } from "react-redux";

import useFetch from "../../hooks/useFetch";
import { api } from "../../config/Api";
import { FilterForFamilies, TableStateValue, FamilyForSelect, MemberForSelect, CategoryForSelect } from "../../helper/interface";
import { setTableData } from "../../store/slice/TableDataSlice";
import { BusinessSearchBy, BusinessSearchData } from "../../helper/constant";
import { Tooltip } from "react-tooltip";

const Filter = ({ values, setFieldValue, setFieldTouched, resetForm, reload, handleChange }: FilterForFamilies) => {
    const dispatch = useDispatch();

    const tableOffsetData = useSelector((state: TableStateValue) => state.table.tableData);

    const { res: families } = useFetch<FamilyForSelect[]>(api.family);
    const { res: member } = useFetch<MemberForSelect[]>(api.member);
    const { res: category } = useFetch<CategoryForSelect[]>(api.category);

    const handleOptionChange = (value: string) => {
        setFieldValue("searchBy", value);
        setFieldValue("value", null);
    };

    const handleFamilyChange = useCallback((selectedItem: MultiValue<FamilyForSelect>) => {
        const familyIds = selectedItem?.map((ele) => ele.familyId).join(",");
        setFieldValue("value", familyIds);
    }, []);

    const handleMemberChange = useCallback((selectedItem: MultiValue<MemberForSelect>) => {
        const memberIds = selectedItem?.map((ele) => ele.memberId).join(",");
        setFieldValue("value", memberIds);
    }, []);

    const handleCategoryChange = useCallback((selectedItem: MultiValue<CategoryForSelect>) => {
        const categoryIds = selectedItem?.map((ele) => ele.categoryId).join(",");
        setFieldValue("value", categoryIds);
    }, []);

    const handleReset = useCallback(() => {
        dispatch(setTableData({ page: 1, limit: 10, maxPage: 1, search: null, orderBy: null, tableFor: "business", metaFilter: null }));
        reload(`${api.business}?page=1&limit=10`);
        resetForm();
    }, [tableOffsetData]);

    const splitIds = values?.value?.split(",");

    return (
        <>
            <Form id="tabulator-html-filter-form" className="flex items-center job-pending-style align-items-baseline">
                <div className="flex items-center sm:mr-4 py-1 job-filter-form z-50">
                    <label className="flex-none mr-2 mb-0">Select filter</label>
                    <div className="tom-select">
                        <Listbox value={values.searchBy} onChange={handleOptionChange}>
                            <Listbox.Button className="form-control ts-input">
                                <div className="item">{values?.searchBy ?? "Select filter type"}</div>
                            </Listbox.Button>
                            <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                                onBlur={() => setFieldTouched("searchBy", true)}
                            >
                                <Listbox.Options className="ts-dropdown single w-full">
                                    <div role="listbox" className="ts-dropdown-content">
                                        {BusinessSearchData.map((fltr: string, i: number) => {
                                            return (
                                                <Listbox.Option
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-3 pr-4 ${
                                                            active || fltr === values.searchBy ? "optionColor" : null
                                                        }`
                                                    }
                                                    key={i}
                                                    value={fltr}
                                                >
                                                    {fltr}
                                                </Listbox.Option>
                                            );
                                        })}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </Listbox>
                    </div>
                </div>
                {values.searchBy === BusinessSearchBy.FAMILY && families?.data ? (
                    <div className="sm:flex flex items-center sm:mr-4">
                        <label className="form-label mr-2">Family</label>
                        <div>
                            <Select
                                isMulti
                                name="families"
                                options={families?.data}
                                value={families?.data?.filter((e) => splitIds?.includes(String(e.familyId)))}
                                onChange={(selectedItem) => handleFamilyChange(selectedItem)}
                                classNamePrefix="select"
                                className={"form-control job-filter-style"}
                                getOptionLabel={(option) => option.primaryMember.fullNameEn}
                                getOptionValue={(option) => option.familyId}
                            />
                        </div>
                    </div>
                ) : null}
                {values.searchBy === BusinessSearchBy.MEMBER && member?.data ? (
                    <div className="sm:flex flex items-center sm:mr-4">
                        <label className="form-label mr-2">Member</label>
                        <div>
                            <Select
                                isMulti
                                name="member"
                                options={member?.data}
                                value={member?.data?.filter((e) => splitIds?.includes(String(e.memberId)))}
                                onChange={(selectedItem) => handleMemberChange(selectedItem)}
                                classNamePrefix="select"
                                className={"form-control job-filter-style py-1"}
                                getOptionLabel={(option) => option.fullNameEn}
                                getOptionValue={(option) => option.memberId}
                            />
                        </div>
                    </div>
                ) : null}
                {values.searchBy === BusinessSearchBy.CATEGORY && category?.data ? (
                    <div className="sm:flex flex items-center sm:mr-4">
                        <label className="form-label mr-2">Category</label>
                        <div>
                            <Select
                                isMulti
                                name="category"
                                options={category?.data}
                                value={category?.data?.filter((e) => splitIds?.includes(String(e.categoryId)))}
                                onChange={(selectedItem) => handleCategoryChange(selectedItem)}
                                classNamePrefix="select"
                                className={"form-control job-filter-style py-1"}
                                getOptionLabel={(option) => option.nameEn}
                                getOptionValue={(option) => option.categoryId}
                            />
                        </div>
                    </div>
                ) : null}
                {values.searchBy === BusinessSearchBy.NAME ? (
                    <div>
                        <div className="flex items-center sm:mr-4 py-1 job-filter-form datePicker">
                            <Tooltip id="simpleTooltipSearch" place="top" />
                            <input
                                name="value"
                                type="text"
                                autoComplete="off"
                                data-tooltip-id="simpleTooltipSearch"
                                data-tooltip-content="Business name"
                                value={values?.value ?? ""}
                                className={"form-control mr-2 sm:w-40 2xl:w-full mt-2 sm:mt-0"}
                                placeholder="Type business name"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                ) : null}
                {values.searchBy === BusinessSearchBy.MOBILE ? (
                    <div>
                        <div className="flex items-center sm:mr-4 py-1 job-filter-form datePicker">
                            <Tooltip id="simpleTooltipSearch" place="top" />
                            <input
                                name="value"
                                type="text"
                                autoComplete="off"
                                data-tooltip-id="simpleTooltipSearch"
                                data-tooltip-content="Mobile number"
                                value={values?.value ?? ""}
                                className={"form-control mr-2 sm:w-40 2xl:w-full mt-2 sm:mt-0"}
                                placeholder="Type mobile number"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                ) : null}

                <div className="flex mt-2 md:mt-0">
                    <button id="tabulator-html-filter-go" type="submit" className="btn btn-primary w-16" disabled={Boolean(!values.value)}>
                        Go
                    </button>
                    <button id="tabulator-html-filter-reset" type="button" className="btn btn-secondary w-16 ml-2" onClick={handleReset}>
                        Reset
                    </button>
                </div>
            </Form>
        </>
    );
};

export default Filter;
