import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { Edit, Trash2 } from "react-feather";
import moment from "moment";
import { Formik } from "formik";
import DeleteModal from "../../modal/DeleteModal";
import CustomTable from "../table/CustomTable";
import useTable from "../../hooks/useTable";
import { api } from "../../config/Api";
import usePathName from "../../hooks/usePathName";
import { ActivateDeactivateDonor, Alignment, Donor, FamiliesMetaFilter, TableStateValue } from "../../helper/interface";
import { onActivateDeactivateDonor, onDeleteDonor } from "../../service/donors";
import Filter from "./Filter";
import { TWENTY, ZERO } from "../../helper/constant";

let initialValues: FamiliesMetaFilter = {
    searchBy: "Family",
};

const Donors = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tableFilterData = useSelector((state: TableStateValue) => state.table.tableData);

    useEffect(() => {
        if (tableFilterData.metaFilter) {
            const metaFilter = tableFilterData.metaFilter;

            initialValues = {
                searchBy: metaFilter.searchBy,
                value: metaFilter.value,
            };
        } else {
            initialValues = {
                searchBy: "Family",
                value: null,
            };
        }
    }, [tableFilterData.metaFilter]);

    const donorTable = useTable<Donor>("Donor", api.donor);

    const [isOpen, setIsOpen] = useState(false);
    const [eduStandard, setEduStandard] = useState<Donor | null>(null);

    usePathName([{ pathName: "Donor", href: "/donors" }]);

    const onDeleteClick = useCallback((record: Donor) => {
        setEduStandard(record);
        setIsOpen(true);
    }, []);

    const onDelete = useCallback(() => {
        if (eduStandard?.donorId) {
            onDeleteDonor(eduStandard?.donorId, dispatch, () => {
                donorTable.fetchApi();
                setIsOpen(false);
            });
        }
    }, [eduStandard, donorTable]);

    const onActivateDeactivate = useCallback(
        (payload: ActivateDeactivateDonor) => {
            onActivateDeactivateDonor(payload, dispatch, donorTable.fetchApi);
        },
        [donorTable]
    );

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    const educationYearCols = [
        {
            title: "#",
            key: "srNumber",
            render: (record: Donor, srNo: number) => {
                return <span>{srNo}</span>;
            },
        },
        {
            title: "MEMBER NAME",
            key: "memberName",
            sortable: true,
            minWidth: true,
            render: (record: Donor) => {
                return <span>{record?.member?.fullNameEn ?? "-"}</span>;
            },
        },
        {
            title: "DESCRIPTION",
            key: "description",
            sortable: false,
            render: (record: Donor) => {
                return (
                    <>
                        <Tooltip id="simpleTooltipForDesc" place="top" />
                        <span
                            data-tooltip-id="simpleTooltipForDesc"
                            data-tooltip-content={record?.descriptionEn?.length > TWENTY ? record?.descriptionEn : ""}
                        >
                            {record?.descriptionEn?.length > TWENTY
                                ? record?.descriptionEn?.slice(ZERO, TWENTY) + "..."
                                : record?.descriptionEn}
                        </span>
                    </>
                );
            },
        },
        {
            title: "MOBILE",
            key: "mobile",
            sortable: false,
            render: (record: Donor) => {
                return <span>{record?.member?.mobile ?? "-"}</span>;
            },
        },
        {
            title: "AMOUNT",
            key: "amount",
            sortable: true,
            render: (record: Donor) => {
                return <span>{record?.amount ? record?.amount : "-"}</span>;
            },
        },
        {
            title: "DATE",
            key: "date",
            sortable: true,
            render: (record: Donor) => {
                return <span>{moment(record?.date).format("DD-MM-YYYY")}</span>;
            },
        },
        {
            title: "LIFE LONG",
            key: "lifeLong",
            align: Alignment.CENTER,
            render: (record: Donor) => {
                return <>{record.lifeLong ? "Yes" : "No"}</>;
            },
        },
        {
            title: "STATUS",
            key: "isActive",
            render: (record: Donor) => {
                const payload = {
                    donorId: record?.donorId,
                    isActive: !record?.isActive,
                };
                return (
                    <>
                        <Tooltip id="simpleTooltipActiveDeactive" place="top" />
                        <div
                            className="form-switch form-check flex justify-center flex-col"
                            data-tooltip-id="simpleTooltipActiveDeactive"
                            data-tooltip-content={record?.isActive ? "Active" : "Inactive"}
                        >
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={() => onActivateDeactivate(payload)}
                                checked={record?.isActive}
                                className={"form-check-input"}
                                type="checkbox"
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "ACTION",
            key: "action",
            align: Alignment.CENTER,
            render: (record: Donor) => {
                return (
                    <>
                        <div className="flex justify-center items-center text-primary">
                            <Tooltip id="simpleTooltipEditDelete" place="top" />
                            <div
                                className="flex items-center mr-3 cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Edit donor"}
                                onClick={() => navigate(`/donors/edit/${record?.donorId}`)}
                            >
                                <Edit className="w-4 h-4 mr-1" />
                                <span className="pt-[2px] leading-[16px]">Edit</span>
                            </div>
                            <div
                                className="flex items-center text-danger cursor-pointer"
                                data-tooltip-id="simpleTooltipEditDelete"
                                data-tooltip-content={"Delete donor"}
                                onClick={() => onDeleteClick(record)}
                            >
                                <Trash2 className="w-4 h-4 mr-1" /> <span className="pt-[2px] leading-[16px]">Delete</span>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];

    const onMetaFilteredSubmit = (value: FamiliesMetaFilter) => {
        const metaFilter = {
            searchBy: value.searchBy,
            value: value.value,
        };

        donorTable.onSearch("", metaFilter);
    };

    return (
        <>
            <DeleteModal
                handleCancel={handleCancel}
                isOpen={isOpen}
                onDelete={onDelete}
                subTitle="Do you really want to delete this donor?"
            />
            <div className="grid grid-cols-12 gap-6 mt-5">
                <CustomTable
                    cols={educationYearCols}
                    data={donorTable.tableData ?? []}
                    tableKey="donorId"
                    reload={donorTable.fetchApi}
                    currentOrder={donorTable.currentOrder}
                    error={donorTable.error}
                    loading={donorTable.loading}
                    tableOffset={donorTable.tableOffset}
                    onSetOrderBy={donorTable.onSetOrderBy}
                    className="xl:justify-between xl:items-center"
                    component={
                        <>
                            <Formik initialValues={initialValues} enableReinitialize onSubmit={onMetaFilteredSubmit}>
                                {(props) => <Filter {...props} reload={donorTable.fetchApi} />}
                            </Formik>
                            <div className="flex mt-5 sm:mt-0 mr-2 sm:mr-0">
                                <Tooltip id="simpleTooltip" place="top" />
                                <button
                                    className="btn btn-outline-primary w-1/2 sm:w-auto whitespace-nowrap"
                                    data-tooltip-id="simpleTooltip"
                                    data-tooltip-content="Add new donor"
                                    onClick={() => {
                                        navigate("/donors/add");
                                    }}
                                >
                                    Add New
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Donors;
