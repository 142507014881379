const Host = process.env.REACT_APP_BACKEND_API;

const api = {
    admin: {
        login: `${Host}/admin/login`,
        forgotPassword: `${Host}/admin/forgotPassword`,
        setPassword: `${Host}/admin/setPassword`,
        changePassword: `${Host}/admin/changePassword`,
        subAdmin: `${Host}/admin/subAdmin`,
    },
    village: `${Host}/admin/village`,
    family: `${Host}/admin/family`,
    member: `${Host}/admin/member`,
    marksheet: {
        educationYear: `${Host}/admin/educationYear`,
        educationStandard: `${Host}/admin/educationStandard`,
        educationBoard: `${Host}/admin/educationBoard`,
        educationMedium: `${Host}/admin/educationMedium`,
        upload: `${Host}/admin/markSheet`,
        deleteMarksheetImage: `${Host}/admin/markSheetImage`,
    },
    donor: `${Host}/admin/donor`,
    category: `${Host}/admin/category`,
    business: `${Host}/admin/business`,
    advertisement: `${Host}/admin/advertisement`,
    annualContribution: `${Host}/admin/annualContribution`,
    globalSettings: `${Host}/admin/globalSettings`,
    tags: `${Host}/admin/tags`,
    event: {
        event: `${Host}/admin/event`,
        deleteEventImage: `${Host}/admin/eventImage`,
    },
    occupation: `${Host}/admin/occupation`,
    adminDirectory: `${Host}/admin/adminDirectory`,
    contributionHistory: `${Host}/admin/contributionHistory`,
    prize: `${Host}/admin/prize`,
    prizeRanks: `${Host}/admin/prizeRank`,
    notifyUsers: `${Host}/admin/notifyUsers`,
};

export { api };
