import { Menu, Transition } from "@headlessui/react";
import { FC, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DOCUMENT_TITLE, PathValue, StateValue } from "../../helper/interface";
import Logo from "../../assets/images/Logo.png";
import { BarChart2, Moon, Sun } from "react-feather";
import { setRole, setToken } from "../../store/slice/BaseSlice";
import HorizontalLogo from "../../assets/images/HorizontalLogo.png";
import { ADMIN_ROLE } from "../../helper/constant";

const Header: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pagePath = useSelector((state: StateValue) => state.base.pagePath);
    const adminRole = useSelector((state: StateValue) => state.base.role);

    const onLogOut = useCallback(() => {
        dispatch(setRole(null));

        dispatch(setToken(null));

        navigate("/auth/login");

        document.title = DOCUMENT_TITLE.ADMIN;
    }, []);

    useEffect(() => {
        if (adminRole === ADMIN_ROLE.superAdmin) {
            document.title = DOCUMENT_TITLE.SUPER_ADMIN;
        }
    }, []);

    const currentMode = localStorage.getItem("Mode") as string;
    const htmlTag = document.querySelector("html");

    const [mode, setMode] = useState(currentMode);

    useEffect(() => {
        if (!mode || mode === null) {
            localStorage.setItem("Mode", "light");
        } else {
            localStorage.setItem("Mode", mode);
        }
        if (htmlTag) {
            htmlTag.className = mode;
        }
    }, [mode]);

    const onModeChange = useCallback(() => {
        localStorage.removeItem("Mode");

        setMode(htmlTag?.className === "light" ? "dark" : "light");
    }, []);

    return (
        <>
            <div className="top-bar-boxed h-[70px] z-[51] relative border-b border-white/[0.08] -mt-7 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 md:pt-0 mb-12">
                <div className="h-full flex justify-between items-center">
                    <div className="-intro-x hidden md:flex">
                        <img alt="Gondaliya parivar" className="h-8 cursor-pointer" src={Logo} onClick={() => navigate("/families")} />
                        <nav aria-label="breadcrumb" className="-intro-x h-full my-auto">
                            <ol className="breadcrumb breadcrumb-light">
                                {pagePath.map((data: PathValue, i: number) => (
                                    <li className="breadcrumb-item active" key={i}>
                                        <Link to={data.href}>{data.pathName}</Link>
                                    </li>
                                ))}
                            </ol>
                        </nav>
                    </div>
                    <img src={HorizontalLogo} alt="" className="w-32" />
                    <div className="text-left flex justify-center items-center">
                        <div
                            className="mr-2 cursor-pointer dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
                            onClick={() => onModeChange()}
                        >
                            {mode === "dark" ? <Sun className="w-6 h-6 text-white" /> : <Moon className="w-6 h-6 text-white" />}
                        </div>
                        <div className="relative inline-block">
                            <Menu>
                                {({ open }) => (
                                    <>
                                        <span className="rounded-md shadow-sm">
                                            <Menu.Button className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110">
                                                <BarChart2 className="w-8 h-8 text-white transform -rotate-90" />
                                            </Menu.Button>
                                        </span>
                                        <Transition
                                            show={open}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                static
                                                className="notification-content__box dropdown-content absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                                            >
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        <div
                                                            className="bg-gray-100 text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left cursor-pointer"
                                                            onClick={() => navigate("change-password")}
                                                        >
                                                            Change password
                                                        </div>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <div
                                                            className="bg-gray-100 text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left cursor-pointer"
                                                            onClick={onLogOut}
                                                        >
                                                            Log out
                                                        </div>
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </>
                                )}
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
